$(".selection-1").on("select2:select", function (e) { 
	var select_val = $(e.currentTarget).val();
	if(frappe.system_user === "no" && frappe.session.user !=="Guest" && frappe.session.user !== "Administrator"){
		swal({
			title: "Warning!",
			text: "Cart will be cleared on changing City!",
			type: "input",
			dangerMode: true,
			buttons: true,
		}).then(function(result) {
			if(result){
				return frappe.callq({
					type: "POST",
					method: "bellamar_website.utils.utils.update_customer_city",
					freeze: true,
					args: {
						city: select_val
					},
					callback: function(r) { console.log(r);
						if(!r.exc) {
							swal(r.message.city,r.message.message,r.message.result).then(function(){ 
								window.location.href = window.location.origin;
							});
						}
					},
					error: function(r){
						swal("Error", r.message,"error");
					}
				});
			}
			else{
				swal("Warning!", "City not changed.","error");
				//revert back current city
				$('.selection-1').select2({
					minimumResultsForSearch: -1,
					dropdownParent: $('#dropDownSelect1')
				}).val(frappe.get_cookie('city')).trigger('change');
			}
		});

	}else if(frappe.session.user ==="Guest"){
		$("body").addClass("loading");
		swal({
			title: "Warning!",
			text: "Cart will be cleared on changing City!",
			type: "input",
			dangerMode: true,
			buttons: true,
		}).then(function(result) {
			if(result){
                frappe.callq({
                    type: "POST",
                    method: "bellamar_website.utils.utils.update_guest_city",
                    freeze: true,
                    args: {
                        city: select_val
                    },
                    callback: function(r) {
                        $("body").removeClass("loading");
                        if(!r.exc) {
                            swal(r.message.city,r.message.message,r.message.result).then(function(){
								window.location.href = window.location.origin;
                            });
                        }
                    },
                    error: function(r){
                        swal("Error", r.message,"error");
                    }
                });
            }
            else{
				swal("Warning!", "City not changed.","error");
				location.reload();
				//revert back current city
				/*$('.selection-1').select2({
					minimumResultsForSearch: -1,
					dropdownParent: $('#dropDownSelect1')
				}).val(frappe.get_cookie('city')).trigger('change');*/
			}
        })
	} 
});



$(".add-to-cart").on("click", function(e) {
	frappe.provide('erpnext.shopping_cart');
	console.log($(e.currentTarget).attr('data-item-code'));
	erpnext.shopping_cart.update_cart({
		item_code: $(e.currentTarget).attr('data-item-code'),
		qty: 1,
		callback: function(r) {
			console.log(r);
			if(!r.exc) {
				toggle_update_cart(1);
				qty = 1;
			}
		},
		btn: this,
	});
});
var toggle_update_cart = function(qty) {
	if(qty>0){
		$("#item-add-to-cart").show();
		$("#item-update-cart").show();
		$("#item-spinner").show().find("input").val(qty);
	}else{
		$("#item-add-to-cart").show();
		$("#item-update-cart").hide();
		$("#item-spinner").show().find("input").val(qty);
	}	
}
